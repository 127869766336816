import React, { Component } from 'react'
import styled from 'styled-components'

const ListOfHashTags = styled.div`
`

const WordSpans = styled.h5`
  margin-right: 10px;
  margin-top: 10px;
  padding: 4px;
  border-radius: 8px;
  color: black;
  border: 1px solid #aaa;
  display: inline-block;
  font-weight: bold;
`

export default class HashTags extends Component {
  render() {
    const { title, hashtags } = this.props;
    const words = hashtags.map((word, index) => {
      return <WordSpans>{word}</WordSpans>;
    });
    return (
      <ListOfHashTags>
        <h4>{title}</h4>
        {words}
      </ListOfHashTags>
    )
  }
}