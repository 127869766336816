import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from "prop-types"
import Img from "./image.js"
import HashTags from './HashTags.jsx'

const RowContent = styled.div`
  max-width: 1100px;
  width: 100%;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  background-color: white;
  * {
    background-color: white;
  }

  @media (max-width: 850px) {
     flex-direction: column;
  }
`
const TextBoxes = styled.div`
  h3 {
    background-color: white;
    font-size: ${(props) => props.titleFontSize ? props.titleFontSize : 24}px;
    margin-bottom: 20px;
  }
  font-size: ${(props) => props.fontSize ? props.fontSize : 18}px;;
  width: ${chooseTextSize}%;
  padding: 20px;

  @media (max-width: 850px) {
     width: 100%;
     order: ${(props) => props.below ? 1 : 2};
  }
`

const Image = styled.div`
  width: ${choosePictureSize}%;
  background-color: black;
  div {
    background-color: black;
    display: block;
    height: 100%;
    div {
      background-color: black;
    }
    img {
      background-color: black;
    }
    picture {
      background-color: black;
    }
  }

  @media (max-width: 850px) {
    width: 100%;
    order: ${(props) => props.below ? 2 : 1};
  }
`

const Description = styled.span`
  display: inline-block;
`

const HashTagsRow = styled.div`
  display: inline-block;
  margin-top: 20px;
`

function choosePictureSize(props) {
  switch(props.pictureSize) {
    case 'smaller':
      return 30;
    case 'larger':
      return 70;
    case 'equal':
      return 50;
    default:
      return 0;
  }
}

function chooseTextSize(props) {
  switch(props.pictureSize) {
    case 'smaller':
      return 70;
    case 'larger':
      return 30;
    case 'equal':
      return 50;
    default:
      return 0;
  }
}

export default class TextWithPicture extends Component {
  render() {
    const { fontSize, titleFontSize, hashtags = [] } = this.props;
    const below = this.props.below ? this.props.below : null;
    const hashtagsMapping = hashtags.map((entries, index) => {
      return (
        <HashTagsRow>
          <HashTags
            title={entries.title}
            hashtags={entries.hashtags}
          />
        </HashTagsRow>
      )
    });
    if (this.props.alignPicture === 'left') {
      return (
        <RowContent>
          <Image below={below} pictureSize={this.props.pictureSize}>
            <Img src={this.props.imgSrc}/>
          </Image>
          <TextBoxes below={below} fontSize={fontSize} titleFontSize={titleFontSize} pictureSize={this.props.pictureSize}>
            <h3>{this.props.title}</h3> 
            <Description>{this.props.textContent}</Description>
            {hashtagsMapping}
          </TextBoxes>
        </RowContent>
      )
    }
    else {
      return (
        <RowContent>
          <TextBoxes below={below} fontSize={fontSize} titleFontSize={titleFontSize} pictureSize={this.props.pictureSize}>
            <h3>{this.props.title}</h3> 
            <Description>{this.props.textContent}</Description>
            {hashtagsMapping}
          </TextBoxes>
          <Image below={below} pictureSize={this.props.pictureSize}>
            <Img src={this.props.imgSrc}/>
          </Image>
        </RowContent>
      )
    }
  }
}

TextWithPicture.propTypes = {
  alignPicture: PropTypes.string.isRequired,
  pictureSize: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
}
