import React, { Component } from 'react'

import Row from '../Components/Rows.jsx'
import TextWithPicture from '../Components/TextWithPicture.jsx'
import PictureGrid from '../Components/PictureGrid.jsx'

export default class TextPictureWithPictureTiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: this.props.imgSrc
    }
    this.onHover = this.onHover.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  onHover(index) {
    this.setState({ imgSrc: this.props.pictures[index].imgSrc })
  }

  onExit() {
    this.setState({ imgSrc: this.props.imgSrc })
  }

  render() {
    const { 
      below = null,
      alignPicture,
      pictureSize,
      title,
      textContent,
      pictures,
      cols,
      fontSize = null,
      titleFontSize = null,
      hashtags = []
    } = this.props;
    return (
      <div>
        <Row noPaddingBottom>
          <TextWithPicture
            below={below}
            alignPicture={alignPicture}
            pictureSize={pictureSize}
            imgSrc={this.state.imgSrc}
            title={title}
            textContent={textContent}
            fontSize={fontSize}
            titleFontSize={titleFontSize}
            hashtags={hashtags}
          />
        </Row>
        <Row noPaddingTop>
          <PictureGrid
            onHover={this.onHover}
            onExit={this.onExit}
            pictures={pictures}
            cols={cols}
          />
        </Row>
      </div>
    )
  }
}