import React, { Component } from 'react'

import Layout from '../Components/layout.js'
import SEO from '../Components/seo.js'
import Pictures from '../Components/Picture.jsx'
import TextWithPicture from '../Components/TextWithPicture.jsx'
import TextTitle from '../Components/TextTitle.jsx'
import Row from '../Components/Rows.jsx'
import TextPictureWithPictureTiles from '../Components/TextPictureWithPictureTiles.jsx'
/*

-Forever 21 Care Label
-Video of PeaPod
-Gildan, Tear away, AlStyles
-Fantasy, spectra
-Woven Labels
-Walmart Price Tags
-Law Label
-Correction Labels

*/

class Products extends Component {
  render() {
    return (
      <Layout>
        <SEO title='Materials' />
        <Pictures splash src={'MaterialSplash3'} />
        <Row noPaddingBottom>
          <TextTitle title='Materials' />
        </Row>

        <TextPictureWithPictureTiles
          below
          alignPicture={'left'}
          pictureSize={'larger'}
          imgSrc={'SatinRoll2'}
          title={'Satin'}
          textContent={'Printed satin labels is the perfect choice for logos and care labels due to their high washability. The material has a soft and silky finish while capturing precise details. It comes in single face or double face and a slit edge or a woven edge. Coupled with various finishes, there are many ways to customize the final product.'}
          hashtags={[
            {
              title: 'Cuts',
              hashtags: ['Cold Cut', 'Hot Cut', 'Ultrasonic']
            },
            {
              title: 'Folds',
              hashtags: ['Center Fold', 'End Fold', 'Book Fold']
            }
          ]}
          pictures={[
            { imgSrc: 'TwoNylonLabelsTop' },
            { imgSrc: 'SatinLabelsAllAnglesTop' },
            { imgSrc: 'ThreeTeestyledTop' },
            { imgSrc: 'OneTeeStyledLabel2' },
            { imgSrc: 'OneSatinLabelTop2' },
            { imgSrc: 'OneBlackRoll' }
          ]}
          cols={6}
        />

        <TextPictureWithPictureTiles
          below
          alignPicture={'right'}
          pictureSize={'larger'}
          imgSrc={'CottonTwillRoll1'}
          title={'Cotton'}
          textContent={'Printed cotton and twill labels are another popular option for their organic look and finish. Normally it comes in a natural off-white color but bleached (white) material is also available. Cotton becomes softer as it is washed more while maintaining its durability. '}
          hashtags={[
            {
              title: 'Cuts',
              hashtags: ['Cold Cut']
            },
            {
              title: 'Folds',
              hashtags: ['Center Fold', 'End Fold', 'Book Fold']
            }
          ]}
          pictures={[
            { imgSrc: 'PawDesignTop2' },
            { imgSrc: 'PawDesignSpreadThree2' },
            { imgSrc: 'PawDesignLabel' },
            { imgSrc: 'FseriesLabels' },
            { imgSrc: 'FseriesClosedTop2' },
            { imgSrc: 'FseriesTop' }
          ]}
          cols={6}
        />

        <TextPictureWithPictureTiles
          below
          alignPicture={'left'}
          pictureSize={'larger'}
          imgSrc={'TearAwayRoll1'}
          title={'Tear-away Acetate'}
          textContent={'Tear-away acetate is a unique material that is designed to rip along the weave easily. These labels are usually sewn onto fitted wears and intimate garments.'}
          hashtags={[
            {
              title: 'Cuts',
              hashtags: ['Cold Cut']
            },
            {
              title: 'Folds',
              hashtags: ['Center Fold', 'End Fold', 'Book Fold']
            }
          ]}
          pictures={[
            { imgSrc: 'OneRoll2' },
            { imgSrc: 'FiveSpectra' },
            { imgSrc: 'LabelTop' },
            { imgSrc: 'AlstyleTop' },
            { imgSrc: 'AlstyleTopAll' },
            { imgSrc: 'TwoSpectra' },
            { imgSrc: 'ThreeLabels' },
            { imgSrc: 'AlstyleTop3' },
            { imgSrc: 'FiveLabelsSpreadTop' },
            { imgSrc: 'ThreeSizeTeeLabel' },
            { imgSrc: 'AlstyleTop2' },
            { imgSrc: 'OneRoll' }
          ]}
          cols={6}
        />
        
        <Row>
          <TextWithPicture
            below
            alignPicture={'right'}
            pictureSize={'larger'}
            imgSrc={'TyvekRoll1'}
            title={'Tyvek'}
            textContent={'Tyvek is an exceptionally robust material that will withstand harsh conditions. It is most commonly seen on pillows, mattresses, and furniture items. Contact us for assistance on creating law tags, one of the most common uses of Tyvek.'}
            hashtags={[
              {
                title: 'Cuts',
                hashtags: ['Cold Cut']
              },
              {
                title: 'Folds',
                hashtags: ['Center Fold', 'End Fold', 'Book Fold']
              }
            ]}
          />
        </Row>

        <TextPictureWithPictureTiles
          below
          alignPicture={'left'}
          pictureSize={'larger'}
          imgSrc={'NylonRoll2'}
          title={'Nylon'}
          textContent={'Nylon labels are made up of synthetic polymers woven together. Nylon clothing labels are slippery and flexible and do not fray. They are almost always found in shoe tongues and are typically affixed to belts, jeans, and headwear.'}
          hashtags={[
            {
              title: 'Cuts',
              hashtags: ['Cold Cut']
            },
            {
              title: 'Folds',
              hashtags: ['Center Fold', 'End Fold', 'Book Fold']
            }
          ]}
          pictures={[
            { imgSrc: 'PeapodTop4' },
            { imgSrc: 'PeaPod' },
            { imgSrc: 'PeapodSpread4Top' }
          ]}
          cols={3}
        />
      </Layout>
    )
  }
}

export default Products