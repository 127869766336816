import React, { Component } from 'react'
import styled from 'styled-components'

import Img from "./image.js"

const Pictures = styled.div`
  max-width: 1100px;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => {
    let { cols } = props;
    if (cols) {
      let str = '';
      for (let i = 0; i < cols; ++i) {
        str += ' 1fr';
      }
      return str;
    }
    else {
      return '1fr 1fr 1fr'
    }
  }};
  grid-gap: 0px;
`

const Image = styled.div`
  div {
    width: 100%;
    display: block;
    height: 100%;
    border: 1px solid #ddd;
  }

  @media (max-width: 850px) {
    width: 100%;
    order: 1;
  }
`

export default class PictureGrid extends Component {
  onHover(event) {
    const { onHover } = this.props;
    if (onHover) onHover(event);
  }

  onExit(event) {
    const { onExit } = this.props;
    if (onExit) onExit(event);
  }

  render() {
    const { pictures, cols } = this.props;
    const entries = [];
    pictures.forEach((pic, index) => {
      entries.push(
        
        <Image onMouseEnter={(e) => this.onHover(index)} key={index}>
          <Img src={pic.imgSrc}/>
        </Image>
      )
    })
    return (
      <Pictures onMouseLeave={(e) => this.onExit(e)} cols={cols}>
        {entries}
      </Pictures>
    )
  }
}

// PictureGrid.propTypes = {
//   : PropTypes.string.isRequired
// }
